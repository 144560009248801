<template>
  <div class="chart-info w-full">
    <vx-card v-if="!isLoading">
      <div class="data-block-heading">
        <h3>Visitors Journeys</h3>
      </div>

      <div class="chart-info-table">
        <table v-if="modifiedUserFlowTree && modifiedUserFlowTree.length > 0">
          <thead>
            <tr>
              <th>{{ $t('views.home.originalCampaign') }}</th>
              <th v-for="i in Array.from({ length: 3 }, (_, i) => i + 1).reverse()" :key="i">{{ $t('views.home.previousSubsite') }} {{ i }}</th>
              <th style="min-width: 60px;">{{ $t('views.home.targetSubsite') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="i in 4" :key="`empty-${i}`"></td>
              <td>{{ selectedVegaUserFlow.url ? removeDomain(selectedVegaUserFlow.url) : '' }} ({{ totalUniqueVisitors }})</td>
            </tr>

            <tr v-for="(row, index) in modifiedUserFlowTree" :key="`${modifiedUserFlowTree}-${index}`">
              <td>{{ row.utmCampaign || '' }}</td>

              <td
                v-for="(pathItem, rowIndex) in row.path"
                :key="`${index}-${rowIndex}-path`"
                :style="`${pathItem ? 'cursor: pointer;' : ''}`"
                @click="clickByPathItemInUserFlow(row, pathItem, rowIndex)"
              >
                {{ pathItem ? `${pathItem} (${row.count})` : '' }}
              </td>

              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>

    <placeholder-text-small v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { analyticsData } from './analyticsData'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  components: {
    PlaceholderTextSmall
  },

  mixins: [analyticsData],

  props: {
    isLoading: {
      type: Boolean
    }
  },

  computed: {
    totalUniqueVisitors() {
      if (!this.vegaVisitorScoreFlow || this.vegaVisitorScoreFlow.length === 0) {
        return 0
      }

      const groupedData = this.groupData(this.vegaVisitorScoreFlow)

      return Object.keys(groupedData).length
    },

    modifiedUserFlowTree() {
      if (!this.vegaVisitorScoreFlow || this.vegaVisitorScoreFlow.length === 0) {
        return []
      }

      const groupedData = this.groupData(this.vegaVisitorScoreFlow)
      const flowMap = this.groupIdenticalFlows(groupedData)
      const flowMapValues = Object.values(flowMap)

      const updateflowMapValues = flowMapValues.map((item) => {
        const { path } = item

        const updatedPath = Array.apply(null, Array(3)).map((item, index) => {
          if (path[index]) {
            return path[index]
          }

          return null
        }).sort((a, b) => {
          if (a === null && b !== null) return -1
          if (a !== null && b === null) return 1

          return 0
        })

        const updatedOriginalPath = Array.apply(null, Array(3)).map((_, index) => {
          if (path[index]) {
            return path[index]
          }

          return null
        }).sort((a, b) => {
          if (a === null && b !== null) return -1
          if (a !== null && b === null) return 1

          return 0
        })

        return {
          ...item,
          path: updatedPath,
          originalPath: updatedOriginalPath
        }
      })

      return updateflowMapValues
    }
  },

  methods: {
    ...mapActions({
      updateSelectedVegaUserFlow: 'dashboard/updateSelectedVegaUserFlow'
    }),

    removeDomain(url) {
      try {
        const { pathname, hostname } = new URL(url)

        return pathname === '/' ? hostname : pathname
      } catch (e) {
        return url
      }
    },

    groupData(data) {
      const groupedData = {}

      const cloneData = JSON.parse(JSON.stringify(data))

      cloneData.forEach(data => {
        data.originalUrl = JSON.parse(JSON.stringify(data.urlPath))
        data.urlPath = this.removeDomain(data.urlPath)

        if (!groupedData[data.visitorId]) {
          groupedData[data.visitorId] = []
        }

        groupedData[data.visitorId].push(data)
      })

      Object.keys(groupedData).forEach(visitorId => {
        groupedData[visitorId].sort((a, b) => new Date(a.created.value) - new Date(b.created.value))
      })

      return groupedData
    },

    groupIdenticalFlows(groupedData) {
      const flowMap = {}

      Object.values(groupedData).forEach(path => {
        const pathString = path.map(step => step.urlPath).join('->')

        if (!flowMap[pathString]) {
          flowMap[pathString] = { count: 0, path: path.map(step => step.urlPath), originalPath: path.map(step => step.originalUrl) }
        }

        flowMap[pathString].count += 1
        flowMap[pathString].utmCampaign = path[0].utmCampaign
      })

      return flowMap
    },

    clickByPathItemInUserFlow(row, pathItem, rowIndex) {
      if (!pathItem) {
        return
      }

      this.updateSelectedVegaUserFlow({ url: row.originalPath[rowIndex] })
    }
  }
}
</script>

<style lang="scss">
.chart-info {
  background: #fff;
  border-radius: 6px;

  .data-block-heading {
    display: flex;
  }
}

.chart-info-table {
  margin: 20px 0 0;

  table {
    width: 100%;
    border-collapse: collapse;
    word-break: break-word;
  }

  th, td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
}
</style>
