var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info w-full relative ml-0 vega-scores-analytics" },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "data-block-heading" }, [
            _c(
              "h3",
              {
                staticClass: "data-block-heading__button",
                on: {
                  click: function ($event) {
                    return _vm.$serverBus.$emit("fetch-data-in-blocks", {
                      query: "bq-visitor-analytics",
                    })
                  },
                },
              },
              [
                _c("DownArrowIcon", {
                  style:
                    "transform: rotate(" +
                    (_vm.isCollapsedVisitorAnalyticsBlock ? "0deg" : "180deg") +
                    "); margin-right: 12px;",
                  attrs: { width: 14, height: 14 },
                }),
                _vm._v("\n        VEGA Visitor Analytics\n      "),
              ],
              1
            ),
          ]),
          !_vm.isCollapsedVisitorAnalyticsBlock
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "analytics-container--right-grow1 vega-analytics-container",
                    staticStyle: { "padding-top": "1.5rem" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "ml-0 margin-rt" },
                      [
                        _c("VegaVisitorStatusOverview", {
                          attrs: { isLoading: _vm.isLoading },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "margin-lt" },
                      [
                        _c("VegaVisitorsWithBuyingIntent", {
                          attrs: { isLoading: _vm.isLoading },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                !_vm.isVegaActivated
                  ? _c("div", { staticClass: "activate-vega" }, [
                      _c("div", { staticClass: "activate-vega__text" }, [
                        _vm._v(_vm._s(_vm.$t("views.home.vegaActivateText"))),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "activate-vega__button",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.goToVegaActivation.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("vega.activateVega")))]
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }