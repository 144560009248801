var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info w-full relative" },
    [
      _vm._m(0),
      !_vm.isLoading
        ? [
            _c("div", { staticClass: "flex flex-row items-start" }, [
              _c(
                "div",
                { staticClass: "relative w-full" },
                [
                  _c("vs-list-header", {
                    staticClass: "mb-0 pb-2 card-title-font-size",
                    attrs: {
                      title: _vm.$t("views.home.visitorStatusOverview"),
                      color: "primary",
                    },
                  }),
                  _c("div", { staticClass: "chart-info__subtitle" }, [
                    _vm._v(
                      _vm._s(_vm.$t("views.home.visitorStatusOverviewSubtitle"))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-2 vs-list--header" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      staticStyle: { width: "17px" },
                      attrs: {
                        text: _vm.$t(
                          "info.dashboard.visitorStatusOverviewInfo"
                        ),
                        position: "top",
                      },
                    },
                    [
                      _c("img", {
                        attrs: { width: "17", height: "17", src: _vm.infoImg },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "visitor-status-list" },
              _vm._l(_vm.visitorStatusOverviewData, function (item) {
                return _c(
                  "div",
                  { key: item.name, staticClass: "visitor-status-list__item" },
                  [
                    _c("VegaIcon", { attrs: { type: item.type, width: 38 } }),
                    _c(
                      "div",
                      { staticClass: "visitor-status-list__item__info" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "visitor-status-list__item__info__title",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.name) +
                                "\n          "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "visitor-status-list__item__info__description",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("views.home.reachedTheStatus"))
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "visitor-status-list__item__amount" },
                      [_vm._v(_vm._s(item.amount))]
                    ),
                    _c(
                      "div",
                      { staticClass: "visitor-status-list__item__percent" },
                      [_vm._v(_vm._s(item.percent) + "%")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "visitor-status-list__item__detailed-description",
                      },
                      [_vm._v(_vm._s(item.description))]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        : _c("placeholder-text-small"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-5" }, [
      _c("div", [_vm._v("VEGA Score: Signaling Urgency for Action")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }