<template>
  <div class="chart-info w-full relative ml-0 vega-scores-analytics">
    <vx-card>
      <div class="data-block-heading">
        <h3
          class="data-block-heading__button"
          @click="$serverBus.$emit('fetch-data-in-blocks', { query: 'bq-visitor-analytics' })"
        >
          <DownArrowIcon :width="14" :height="14" :style="`transform: rotate(${isCollapsedVisitorAnalyticsBlock ? '0deg' : '180deg'}); margin-right: 12px;`" />
          VEGA Visitor Analytics
        </h3>
      </div>

      <template v-if="!isCollapsedVisitorAnalyticsBlock">
        <div class="analytics-container--right-grow1 vega-analytics-container" style="padding-top: 1.5rem;">
          <div class="ml-0 margin-rt">
            <VegaVisitorStatusOverview :isLoading="isLoading" />
          </div>

          <div class="margin-lt">
            <VegaVisitorsWithBuyingIntent :isLoading="isLoading" />
          </div>
        </div>

        <div v-if="!isVegaActivated" class="activate-vega">
          <div class="activate-vega__text">{{ $t('views.home.vegaActivateText') }}</div>
          <button class="activate-vega__button" @click.prevent="goToVegaActivation">{{ $t('vega.activateVega') }}</button>
        </div>
      </template>
    </vx-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { analyticsData } from './analyticsData'
import VegaVisitorsWithBuyingIntent from './VegaVisitorsWithBuyingIntent.vue'
import VegaVisitorStatusOverview from './VegaVisitorStatusOverview.vue'

import DownArrowIcon from '@/components/icons/DownArrowIcon'

export default {
  components: {
    DownArrowIcon,
    VegaVisitorsWithBuyingIntent,
    VegaVisitorStatusOverview
  },

  mixins: [analyticsData],

  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean
    }
  },

  data() {
    return {
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },

  computed: {
    ...mapGetters({
      isCollapsedVisitorAnalyticsBlock: 'dashboard/isCollapsedVisitorAnalyticsBlock',
    }),
  },

  methods: {
    goToVegaActivation() {
      if (this.isMobile) {
        return this.$router.push({ name: 'vega' })
      }

      this.$router.push({ name: 'payment', params: { action: 'activate-vega' } })
    },
  }
}
</script>
<style lang="scss">
.data-block-heading {
  display: flex;

  &__button {
    cursor: pointer;
  }
}

.apexcharts-datalabel-label {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
}
</style>
<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}
</style>

<style lang="scss">
.vega-scores-analytics {
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: #262629;
  }

  .vs-table-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #262629;
    text-transform: uppercase;
  }

  &__list-view--column {
    word-break: break-all;
  }

  &__list-view {
    &__campaign {
      min-width: 150px;
    }

    &__url {
      min-width: 200px;
    }

    &__score {
      span {
        display: flex;
      }

      &__label {
        display: inline-block;
        margin-left: 14px;
        font-size: 17px;
        line-height: 21px;
        color: #726f7b;
        white-space: nowrap;
      }
    }
  }
}
</style>

<style lang="scss">
.vega-analytics-container {
  .margin-rt,
  .margin-lt {
    display: flex;

    .chart-info {
      .vx-card {
        height: 100%;
      }
    }
  }

  .vs-list--header,
  .vs-list--title {
    margin-bottom: 0 !important;
    padding: 0 !important;
    color: rgb(38, 38, 41) !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }

  .vs-list--header {
    padding-bottom: 5px !important;
  }

  .chart-info__subtitle {
    font-size: 13px;
    line-height: 16px;
    color: rgb(194, 192, 197) !important;
  }

  .summary-info {
    .vs-list--title {
      color: rgb(255, 255, 255) !important;
    }

    .chart-info__subtitle {
      color: rgb(255, 255, 255) !important;
    }
  }

  .con-vs-tooltip {
    padding-top: 2px;
  }
}
</style>

<style lang="scss" scoped>
.analytics-container {
  @media (min-width: 1024px) {
    flex-direction: row;

    .margin-rt {
      margin-right: 10px;
    }
    .margin-lt {
      margin-left: 10px;
    }

    &--left {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      display: flex;
      flex-direction: column;

      &-visitors {
        margin-bottom: 20px;
      }
      &-grow1 {
        display: flex;

        > div {
          flex-grow: 1;
          flex-basis: 0;
          flex-shrink: 0;
          margin-bottom: 20px;
        }
      }
    }

    &--right {
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      &-grow1 {
        display: flex;
        flex-direction: row;

        > div {
          flex-grow: 1;
          flex-basis: 0;
          flex-shrink: 0;
          margin-left: 20px;
          margin-bottom: 20px;
        }
      }

      &--visitors-height {
        height: 309.75px;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .margin-rt {
      margin-right: 0px;
    }
    .margin-lt {
      margin-left: 0px;
    }

    &--right {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;

      &-grow1 {
        > div {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
