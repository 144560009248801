var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info w-full relative vega-scores-analytics ml-0" },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "data-block-heading" }, [
            _c(
              "h3",
              {
                staticClass: "data-block-heading__button",
                on: {
                  click: function ($event) {
                    return _vm.$serverBus.$emit("fetch-data-in-blocks", {
                      query: "bq-scores-analytics",
                    })
                  },
                },
              },
              [
                _c("DownArrowIcon", {
                  style:
                    "transform: rotate(" +
                    (_vm.isCollapsedWebsiteAnalyticsBlock ? "0deg" : "180deg") +
                    "); margin-right: 12px;",
                  attrs: { width: 14, height: 14 },
                }),
                _vm._v("\n        VEGA Website Analytics\n      "),
              ],
              1
            ),
          ]),
          !_vm.isCollapsedWebsiteAnalyticsBlock
            ? [
                !_vm.isLoading
                  ? _c(
                      "div",
                      [
                        _c(
                          "vs-table",
                          {
                            key: _vm.tableKey,
                            ref: "table",
                            attrs: {
                              pagination: "",
                              "max-items": _vm.itemsPerPage,
                              search: "",
                              data: _vm.scoresAnalyticsData,
                              noDataText: _vm.$t("views.home.noDataAvailable"),
                            },
                            on: { selected: _vm.setVegaUserFlowUrl },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var data = ref.data
                                    return [
                                      _c(
                                        "tbody",
                                        _vm._l(data, function (tr, indextr) {
                                          return _c(
                                            "vs-tr",
                                            {
                                              key: indextr,
                                              attrs: { data: tr },
                                            },
                                            [
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass:
                                                    "vega-scores-analytics__list-view--column vega-scores-analytics__list-view__url",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(tr.u) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass:
                                                    "vega-scores-analytics__list-view--column",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(tr.t) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass:
                                                    "vega-scores-analytics__list-view--column vega-scores-analytics__list-view__score",
                                                },
                                                [
                                                  _c("VisitorScoring", {
                                                    attrs: {
                                                      score: tr.a,
                                                      online: true,
                                                      isVegaActivated: true,
                                                      isVegaWidgetEnabled: true,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  staticClass:
                                                    "vega-scores-analytics__list-view--column vega-scores-analytics__list-view__score",
                                                },
                                                [
                                                  _c("VisitorScoring", {
                                                    attrs: {
                                                      score: tr.m,
                                                      online: true,
                                                      isVegaActivated: true,
                                                      isVegaWidgetEnabled: true,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3584746761
                            ),
                            model: {
                              value: _vm.selected,
                              callback: function ($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-column items-start flex-grow justify-center",
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mb-3 rounded-lg cursor-pointer flex w-full flex-row items-center justify-start text-base vega-scores-analytics__title",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("views.home.scoresAnalytics")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                _c("vs-th", { attrs: { "sort-key": "url" } }, [
                                  _vm._v("URL"),
                                ]),
                                _c(
                                  "vs-th",
                                  { attrs: { "sort-key": "totalVisitors" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("views.home.totalVisitors"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-th",
                                  { attrs: { "sort-key": "score" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "views.home.averageScoreOnWebpage"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-th",
                                  { attrs: { "sort-key": "score" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "views.home.highestScoreOnWebpage"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        !_vm.isVegaActivated
                          ? _c("div", { staticClass: "activate-vega" }, [
                              _c(
                                "div",
                                { staticClass: "activate-vega__text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("views.home.vegaActivateText")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "activate-vega__button",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.goToVegaActivation.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("vega.activateVega")))]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("placeholder-text-small"),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }