<template>
  <div>
    <img :src="require(`@/assets/images/vega/${modifiedType}.svg`)" />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    }
  },

  computed: {
    modifiedType() {
      if (!this.type || !['facebook', 'twitter', 'linkedin', 'tiktok', 'pinterest', 'google', 'mail'].includes(this.type)) {
        return 'other'
      }

      return this.type
    }
  }
}
</script>
